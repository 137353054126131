/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "syncfusion-styles.scss";

:root {
  --contrast: #e6eaef;
  --contrast-dark: #cacfdd;
  --contrast-darkest: #b5b8cf;
  --contrast-dark-for-check-box: #b0b5c2;
}

$border-grey: #575962;
$brand-color: #928cd6;
$brand-color2: #ff6600;
$brand-color-red: #a91616;
$brand-grey: #5b5b5b;
$danger: #f4516c;
$success: #77dd77;
$primary: #5867dd;
$black: black;
$warning: #ffc107;
$default-font: Poppins, "Helvetica Neue", sans-serif !important;
$contrast: var(--contrast);
$contrast-dark: var(--contrast-dark);
$contrast-darkest: var(--contrast-darkest);
$contrast-dark-for-check-box: var(--contrast-dark-for-check-box);

body {
  overflow-y: scroll;
}

div.top {
  position: sticky;
  top: 70px;
  background-color: #fff;
  display: flex;
  z-index: 1550;
}

.default-form {
  overflow: visible;

  date-time-input {
    padding-right: 0px;
  }

  .ngb-tp {
    justify-content: flex-end;

    .ngb-tp-input-container {
      width: 4.5em;
    }
  }
  .form-group.row > label {
    display: flex;
    align-items: center;
    max-height: 3rem;
    overflow: visible;
  }
}

.general-details-form {
  width: calc(100% - 105px);
  margin-top: -42px;
}

.mat-table__wrapper {
  width: 100%;
  overflow-x: auto;
}

.mat-table {
  font-family: $default-font;
}

.mat-table .mat-row:nth-of-type(odd) {
  background-color: $contrast;
}

.mat-table__wrapper .mat-table {
  min-width: 1000px;
  width: 100%;
}

.mat-table__wrapper--dashboard .mat-table {
  min-width: 600px !important;
}

.mat-table__wrapper .mat-table .email-cell {
  word-wrap: normal;
  width: 180px;
  flex: auto;
}

.e-spinner-pane.e-spin-show {
  z-index: 1; // required for pivot table to not block datepicker
}

.m-mat-dialog-container__wrapper .mat-dialog-container {
  padding: 0;
  overflow-x: hidden;
  overflow: visible;

  .m-portlet {
    margin-bottom: 0;
  }

  .alert {
    border-radius: 0 !important;
    width: 1032px;
  }
}

.mat-dialog-container {
  overflow: visible !important;
}

.mat-dialog-container .mat-dialog-content {
  overflow: visible !important;
}
.mat-table__wrapper--sub .mat-table {
  min-width: 400px;

  .mat-cell:last-child {
    flex: 0 0 100px;
  }

  .mat-header-cell:last-child {
    flex: 0 0 100px;
  }
}

.ml-auto {
  margin-left: auto;
}

.example-full-width {
  width: 100%;
}

mat-radio-button {
  padding-right: 16px;
}

.m-form--alert {
  margin-top: 30px;
}

.mat-column-checkbox {
  flex: 0 0 68px;
}

.mat-column-id {
  flex: 0 0 68px;
}

.mat-column-main {
  flex: 0 0 568px;
}

.mat-table__bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mat-column-vinCode {
  flex: 0 0 170px;
}

.m-portlet-mx-0 {
  margin: -1.1rem -2.2rem 0;
  overflow-x: hidden;
}

.mat-table__wrapper .mat-table .mat-row-editing.mat-row-inactive {
  height: 0;
  min-height: 0;
  padding: 0 !important;
  border-bottom: 0 !important;
}

/* CUSTOM */

m-footer {
  left: 0px !important;
}

.highlight-red {
  background-color: lighten($brand-color-red, 55%) !important;
}

.highlight,
.mat-table .mat-row:hover,
table.table-hover-brand tbody tr:hover {
  background: #928cd6 !important;
}

.highlight *,
.mat-table .mat-row:hover *,
table.table-hover-brand tbody tr:hover {
  color: #ffffff !important;
}

.highlight .mat-checkbox-frame,
.mat-table .mat-row:hover .mat-checkbox-frame {
  border-color: #ffffff;
}

.highlight.non-selectable,
.mat-table .mat-row.non-selectable:hover,
table.table-hover-brand tbody tr.non-selectable:hover {
  background: inherit !important;
}

.non-selectable.highlight *,
.mat-table .mat-row.non-selectable:hover *,
table.table-hover-brand tbody tr.non-selectable:hover {
  color: inherit !important;
}

.highlight.non-selectable .mat-checkbox-frame,
.mat-table .mat-row.non-selectable:hover .mat-checkbox-frame {
  border-color: inherit !important;
}

.m-brand__logo-wrapper > img {
  height: 3rem;
}

i.m-menu__link-icon::before {
  font-size: 2rem;
}

button > .mat-button-wrapper > mat-icon {
  position: relative;
  font-size: 1.7rem !important;
  left: -5px;
  top: 1px;
}

.mat-table button {
  .mat-button-wrapper > mat-icon {
    font-size: 2rem !important;
  }
}

.mat-cell:hover,
.mat-cell:hover * {
  cursor: pointer;
}

.list-action-buttons-customs {
  float: right;
  width: 395px;
}

.list-action-buttons-customs > button {
  margin-left: 8px;
}

.list-action-buttons {
  float: right;
  row-gap: 4px;
}

.list-action-buttons > button {
  margin-left: 8px;
}

.list-action-buttons > .dropdown {
  margin-left: 8px;
}

.spinner-container {
  height: 360px;
  width: 390px;
  position: fixed;
}

.spinner-container mat-spinner {
  margin: 100px auto 0 auto;
}

mat-paginator {
  margin-top: 15px;
}

mat-form-field.search-field-25 {
  width: 25%;
}

mat-form-field.search-field-33 {
  width: 33%;
}

mat-form-field.search-field-50 {
  width: 50%;
}

.mat-tab-label-active {
  background-color: #716aca;
  color: #ffffff !important;
  opacity: 1 !important;
}

div[mat-dialog-actions] button,
div[mat-dialog-actions] a {
  margin-right: 8px;
}

div[mat-dialog-actions] {
  margin-bottom: unset;
}

.details-container {
  display: flex;
  flex-direction: column;
}

.details-container > form > * {
  width: 100%;
}

div.m-menu__submenu.m-menu__submenu--center.m-menu__submenu--fixed {
  margin-left: 560px !important;
}

div.m-menu__submenu.m-menu__submenu--center.m-menu__submenu--fixed span.m-menu__arrow.m-menu__arrow--adjust {
  left: 235px !important;
}

.icon-button-alignment-fix {
  position: relative;
  top: -2px;
}

.mat-header-row .mat-header-cell {
  font-weight: bold;
  font-size: 14px;
}

.operational-files-list mat-cell div[ng-reflect-message]:hover {
  font-weight: bold !important;
}

.mat-tooltip {
  font-size: 12px !important;
}

// remove this rule if working with multiple task lines
operational-file-steps-list .m-widget2 .m-widget2__item .m-widget2__desc {
  padding-left: 1rem;
}

operational-file-steps-list .m-dropdown__content .m-nav__link-text {
  font-size: 1.1rem;
}

operational-file-steps-list .m-nav .m-nav__item > .m-nav__link .m-nav__link-icon {
  font-size: 1.6rem;
}

operational-file-steps-list .m-widget2__item .m-widget2__desc .m-widget2__deadline .m-widget2__link {
  color: #575962;
}

operational-file-steps-list .m-widget2__item .m-widget2__desc span.m-widget2__deadline {
  margin-top: 0.25rem;
  display: block;
}

operational-file-steps-list .m-widget2__item label.m-checkbox {
  margin-top: -10px;
}

.list-item-container button[mat-icon-button] {
  margin-top: -10px;
}

.list-item-container {
  padding: 5px 0px 5px 20px !important;
}

.full-width-table {
  width: 100%;
}

.m-portlet .m-portlet__head .m-portlet__head-caption .m-portlet__head-title .m-portlet__head-text {
  font-size: 1.5rem;
}

.display-none {
  display: none !important;
}

.hide {
  visibility: hidden !important;
}

.step-item-container .m-widget2__actions {
  padding-left: 0rem !important;
  padding-right: 1rem !important;
}

hr.portlet-separator {
  padding: 0;
  margin-bottom: 2.2rem;
  margin-top: 2.2rem;
}

.operational-files-filter .mat-form-field-infix {
  padding: 0.4375em 0 0.9em 0 !important;
}

m-portlet.full-width-header-portlet .m-portlet__head-caption {
  width: 100%;
}

.mat-tab-group {
  font-family: inherit !important;
}

.required:after {
  content: " *";
}

.either-one-required:after {
  content: " *";
  color: rgb(209, 97, 97);
}

textarea.form-control,
input.form-control,
select.form-control {
  border-color: $contrast-dark;
}

button {
  background-color: $contrast;
  border-color: $contrast-dark;
}

.input-group .input-group-append > .input-group-text,
.input-group .input-group-prepend > .input-group-text {
  background-color: $contrast !important;
  border-color: $contrast-dark !important;
}

.ng-placeholder {
  color: #bab5b5 !important;
}

.custom-component-label {
  color: #3f4047 !important;
}

input.m-checkbox,
.nav-item:hover > a,
.btn.btn-secondary {
  border-color: $contrast-dark !important;
}

.m-radio > span,
.m-checkbox > span {
  border-color: $contrast-dark-for-check-box !important;
}

.modal-header {
  border-bottom-color: $contrast-dark !important;
}

.modal-footer {
  border-top-color: $contrast-dark !important;
}

.m-portlet .m-portlet__head,
.nav-tabs,
.m-separator {
  border-bottom-color: $contrast-dark !important;
}

tr.detail-row {
  height: 0px !important;
}

.element-row td {
  border-top-width: 0;
}

td.mat-cell,
td.mat-footer-cell {
  border: none !important;
}

.mat-header-row .mat-header-cell {
  border-bottom: 1.25px solid $contrast-darkest !important;
}

tr.element-row.mat-row {
  border-top: 0.75px solid $contrast-darkest !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
}

.mat-table {
  border-bottom: 1px solid $contrast-darkest !important;
}

input.form-control,
select.form-control {
  font-family: $default-font;
  padding: 0 1.15rem !important;
}

html,
body,
.mat-tab-label,
input.mat-form-field-autofill-control ~ span.mat-form-field-label-wrapper,
button,
.mat-paginator,
.mat-paginator-page-size .mat-select-trigger,
.mat-select-value,
.mat-option-text,
mat-select ~ span.mat-form-field-label-wrapper > label,
input.mat-input-element,
ng-select,
textarea {
  font-family: $default-font;
}

.ng-option {
  color: #575962;
}

.mat-tab-label-content {
  font-size: 16px !important;
}

.mr-6 {
  margin-right: 4.5rem !important;
}

.ml-6 {
  margin-left: 4.5rem !important;
}

.button-reset-select {
  background: transparent;
}

.mat-footer-row {
  font-weight: bold;
}

.mat-table {
  .mat-header-row,
  .mat-row {
    .mat-header-cell,
    .mat-cell {
      padding: 0 12px;
    }
  }
}

.m-header-menu.m-header-menu--skin-light
  .m-menu__nav
  > .m-menu__item.m-menu__item--active
  > .m-menu__link
  .m-menu__link-text,
.m-header-menu.m-header-menu--skin-light .m-menu__nav > .m-menu__item:hover > .m-menu__link .m-menu__link-text,
.m-header-menu.m-header-menu--skin-light
  .m-menu__nav
  > .m-menu__item.m-menu__item--hover
  > .m-menu__link
  .m-menu__link-text {
  font-weight: 500 !important;
}

div.nested-list-container {
  padding: 0.7em;
}

ul > li > button {
  position: relative;
  top: -2px;
}

.mat-column-address {
  padding: 0.5em 0;
}

.portlet-body-half-top-padding .m-portlet__body {
  padding-top: 1rem !important;
}

.no-label-checkbox-offset-fix {
  width: 18px !important;
  margin-top: 8px !important;
}

label.m-checkbox > input:disabled ~ span {
  background: darkgray !important;
}

.m-checkbox > input:disabled ~ span:after {
  border-color: white !important;
}

ngb-typeahead-window {
  max-height: 200px;
  overflow-y: scroll;
}

.ng-dropdown-panel.ng-select-bottom {
  top: unset !important;
}

.ng-select.select-autoheight.ng-invalid {
  margin-bottom: 0 !important;
}
.ng-select.select-autoheight {
  margin-bottom: min(10px) !important;

  .ng-dropdown-panel.ng-select-bottom {
    top: unset;
  }

  > .ng-select-container {
    height: unset !important;
  }

  .ng-input {
    display: none;
  }

  .ng-value.ng-star-inserted {
    max-width: 94%;
  }

  .ng-value-container {
    width: 93%;
  }

  .ng-value-label {
    vertical-align: middle;
    max-width: 94%;
    overflow: hidden;
    white-space: nowrap;
  }

  .ng-placeholder {
    top: 6px !important;
  }
}

.ng-select.select-autoheight-input {
  width: calc(100% - 48.8px) !important;

  .ng-dropdown-panel.ng-select-bottom {
    top: unset;
  }

  > .ng-select-container {
    min-height: 3.25rem !important;
    height: unset !important;
  }

  .ng-value.ng-star-inserted {
    max-width: 94%;
  }

  .ng-value-container {
    width: 93%;
  }

  .ng-value-label {
    vertical-align: middle;
    max-width: 94%;
    overflow: hidden;
    white-space: nowrap;
  }

  .ng-placeholder {
    top: 11px !important;
  }
}

.ng-select {
  border: 0px !important;
  border-color: transparent !important;
  margin: 0px !important;
  padding: 0 !important;

  > .ng-select-container {
    border-color: $contrast-dark;
    height: calc(2.95rem + 2px) !important;
  }

  .ng-input input[role="combobox"] {
    padding-left: 5px;
    width: 97% !important;
  }
}

.btn + .btn {
  margin-left: 5px;
}

.card.no-border {
  border-width: 0;
}

.card.no-border .list-group .list-group-item {
  border-width: 0;
}

.ng-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-feedback,
.ng-invalid-submitted ~ .invalid-feedback {
  display: block;
}
.invalid-feedback {
  font-size: 100% !important;
}

.ng-select .ng-value-label {
  font-weight: 400;
}

.ng-select .ng-select-container {
  color: #575962 !important;
}

.ng-select .ng-select-container .ng-value-container {
  padding-left: 1.15rem;
  padding-right: 1.15rem;
}

.ng-select .ng-input input {
  padding-top: 0.5rem;
}

ng-select.ng-invalid-submitted .ng-select-container {
  border-color: $danger !important;
}

ng-select.ng-invalid-submitted .ng-dropdown-panel {
  border-color: $danger !important;
}

ng-select.ng-select-focused.ng-invalid-submitted {
  // box-shadow: 0 0 0 0.2rem rgba(244, 81, 108, 0.25) !important;

  .ng-select-container,
  .ng-dropdown-panel {
    border-color: $danger !important;
    box-shadow: 0 0 0 0.2rem rgba(244, 81, 108, 0.25) !important;
  }
}

textarea.form-control:hover,
input.form-control:hover,
.ng-select .ng-select-container:hover {
  box-shadow: 0.7px 0.7px 0.7px $contrast-dark !important;
  border-color: $contrast-darkest !important;

  &.is-invalid {
    border-color: $danger !important;
  }
}

ng-select.ng-invalid-submitted.ng-touched .ng-select-container {
  border-color: $danger !important;
}

ng-select.ng-invalid-submitted.ng-touched .ng-dropdown-panel {
  border-color: $danger !important;
}

ng-select.ng-select-focused.ng-invalid-submitted.ng-touched {
  // box-shadow: 0 0 0 0.2rem rgba(244, 81, 108, 0.25) !important;

  .ng-select-container,
  .ng-dropdown-panel {
    border-color: $danger !important;
    box-shadow: 0 0 0 0.2rem rgba(244, 81, 108, 0.25) !important;
  }
}

ngb-datepicker,
.input-group {
  &.is-invalid {
    :focus {
      box-shadow: 0 0 0 0.2rem rgba(244, 81, 108, 0.25) !important;
      border: inherit;
    }

    border: solid 1px $danger !important;
  }
}

date-input {
  &.is-invalid .input-group {
    input:focus {
      box-shadow: 0 0 0 0.2rem rgba(244, 81, 108, 0.25) !important;
      border: inherit;
    }
    border: solid 1px #f4516c !important;
    border-radius: 5px;
  }
}

angular-editor {
  &.is-invalid {
    .angular-editor-textarea {
      border: solid 1px $danger !important;

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(244, 81, 108, 0.25) !important;
        border: inherit;
      }
    }
  }
}

angular-editor.is-invalid .angular-editor-textarea {
  border: solid 1px $danger !important;
}

angular-editor.is-invalid .angular-editor-textarea:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 81, 108, 0.25) !important;
}

.angular-editor-textarea,
.angular-editor-wrapper,
.angular-editor-toolbar {
  border-radius: 0.25rem;
}

ng-select.ng-invalid .ng-select-container,
ng-select.ng-invalid .ng-dropdown-panel {
  border-color: $contrast-dark !important;
}

ng-select.ng-select-focused.ng-invalid .ng-select-container,
ng-select.ng-select-focused.ng-invalid .ng-dropdown-panel {
  border-color: #716aca !important;
}

.ng-select-opened .ng-select-container,
.ng-select-opened .ng-dropdown-panel,
.ng-select-opened .ng-value-container {
  border-color: #716aca !important;
}

ng-select.ng-select-typeahead.form-control {
  width: 100% !important;
}

ng-select.ng-select-typeahead.is-invalid.ng-select-focused.ng-invalid.form-control {
  box-shadow: 0 0 0.2rem 0.1rem $danger !important;
  width: 100% !important;

  ng-dropdown-panel {
    display: none;
  }

  .ng-select-container,
  .ng-dropdown-panel {
    border-color: #706aca00 !important;
  }
}

div.mat-tab-body-wrapper,
mat-tab-group.mat-tab-group {
  height: 100% !important;
}

.ng-select.ng-select-disabled > .ng-select-container,
input.form-control:read-only,
input.form-control[readonly] {
  background-color: $contrast;
  border-color: $contrast-dark;
}

label.radio-label-top-align-fix {
  padding-top: 0 !important;
}

loading-overlay {
  z-index: 999999999;
  .mat-progress-spinner {
    // px offset should be the same as the control"s dimensions
    left: -80px;
    top: -80px;
  }
}

ul.nav-tabs .nav-link.active {
  background-color: $primary;
  color: #fff;
}

.chip {
  display: inline-block !important;
  height: 40px !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.6) !important;
  line-height: 40px !important;
  padding: 0 12px !important;
  margin-right: 1rem !important;
}

.chip .close {
  cursor: pointer;
  float: right;
  font-size: 16px;
  line-height: 40px;
  padding-left: 8px;
}

@media (min-width: 1025px) {
  .m-portlet.m-portlet--full-height .m-portlet__body {
    min-height: 400px !important;
    height: calc(100% - 2.2rem - 2.2rem);
  }
}

#operationalFileFilterBar {
  margin-bottom: 3px;
}

.steps-container .single-step {
  cursor: pointer;
}
/* Animate items as they’re being sorted. */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cursor-grab {
  cursor: grab !important;
}

.cdk-drag {
  cursor: move; /* fallback */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.step-actions .la,
.remarks-actions .la {
  cursor: pointer !important;
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-placeholder {
  font-weight: bold;
}

.cdk-drag-preview {
  visibility: hidden;
}

.display-flex {
  display: flex;
}

.vertical-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.white-space-pre {
  white-space: pre;
}

.table .mat-icon-button,
.ngx-datatable .mat-icon-button {
  line-height: 0;
  height: 0;
}

.table thead > tr > th,
.ngx-datatable .datatable-header {
  font-weight: bold;
}

//dynamic forms style
li.help-block.ng-star-inserted {
  color: red;
  list-style-type: none;
}

//dynamic forms style
ul.has-error.ng-star-inserted {
  padding: 0;
}

.general-details-form dynamic-bootstrap-input > .ng-dirty.ng-invalid.ng-touched {
  input.ng-invalid,
  .ng-select-invalid {
    border: solid 1px red;
  }
}

.general-details-form h5 {
  margin-bottom: 1.5rem;
}

.general-details-form .footer-section > h5 {
  margin-top: 1.5rem;
}

.btn > i + span {
  margin-left: 5px;
}

ul.goods-imo-classifications > li > u.description {
  padding-left: 3.4rem !important;
}

.mat-table .badge {
  font-size: 90% !important;
  padding: 0.5em 0.4em !important;
  font-weight: 200 !important;
}

.mat-sort-header-button {
  text-align: left !important;
}

table.table-hover-brand tbody tr:hover td {
  cursor: pointer;
}

.manual-error {
  color: red !important;
}

dynamic-bootstrap-form-control .col {
  width: 50% !important;
}

ngb-tabset.routed > ul.nav-tabs > li > a.nav-link.active a {
  color: #fff;
}

ngb-tabset.routed > ul.nav-tabs > li.nav-item > a > a {
  color: #3f4047;
}

ngb-tabset.routed > ul.nav-tabs a.nav-link {
  padding: 0;
}

ngb-tabset.routed > ul.nav-tabs > li.nav-item > a > a {
  padding: 0.5rem 1rem;
  display: inline-block;
}

textarea:read-only {
  background-color: $contrast !important;
  border-color: $contrast-dark;
}

.height-min-content {
  height: min-content !important;
}

.ng-select.ng-select-multiple > .ng-select-container .ng-value-container {
  padding: 0;
}

.ng-select.ng-select-multiple > .ng-select-container .ng-value-container > .ng-value {
  margin: 0 0 0 0.3rem;
  padding: 0.3rem;
}

.ng-select.ng-select-multiple {
  > .ng-select-container {
    > .ng-value-container {
      > .ng-placeholder {
        left: 10px;
        top: 12px;
      }
    }
  }
}

aside {
  width: 400px;
}

.danger {
  background-color: $danger;
}

.success {
  background-color: $success;
}

div.included-customs-doctypes-row {
  padding-left: 1.1rem;
}

#sidebar-drawer {
  width: 300px;
}

.cursor-pointer {
  cursor: pointer;
}

.display-block {
  display: block !important;
}

.mat-drawer-content,
.mat-drawer-container {
  background-color: transparent !important;
}

.operational-file-steps-container .step-actions,
.operational-file-steps-container .step-content {
  margin: 1rem 0;

  .m-checkbox {
    margin: 0;
    position: relative;
    top: 5px;
  }
}

div.m-grid--desktop div.m-portlet {
  min-height: 76vh;
}

.operational-file-filter-error.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $danger;
}

div[mat-dialog-actions] button.btn-secondary {
  margin-right: 0;
}

.mat-cell span.la {
  font-size: 26px;
}

.mat-cell span.la:focus {
  outline-color: transparent !important;
}

.operational-file-card {
  width: 24rem;
  min-height: 11rem;

  :hover {
    background: #f0eefd;
    cursor: pointer;
  }
}

.destination-detail-card {
  min-width: 24rem;
  min-height: 11rem;

  :hover {
    background: #f0eefd;
  }
}

.isSelected > .operational-file-card {
  border: 2px $brand-color solid;
}

.budget-budget-result > i:hover,
.budget-result > i:hover,
.budget-current-result > i:hover,
.budget-actual-result > i:hover {
  cursor: pointer;
}

.m-login__aside {
  background: $brand-color2;
}

#m_login.m-login.m-login--6 .m-login__aside .m-grid__item .m-grid__item {
  .m-login__title {
    font-weight: bold;
  }

  .m-login__title,
  .m-login__subtitle {
    color: #ffffff !important;
  }
}

#m_login .m-login__info .m-link {
  color: #ffffff !important;
}

.m-login__action,
.m-login__body {
  button.btn-brand {
    background-color: $brand-color2;
    border: solid 1px $brand-color2;
  }

  button.btn-brand:active {
    background-color: darken($brand-color2, 10%) !important;
    border: solid 1px darken($brand-color2, 10%) !important;
  }

  .m-link,
  .m-link:hover,
  .m-link:active,
  .m-link:focus {
    color: $brand-grey;
  }
}

.pdfViewer-viewerMainContainer {
  height: 100% !important;
  overflow: inherit !important;
}

.modal-xl {
  min-width: 80vw;
}

div.mat-select-panel {
  background-color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  .badge {
    font-weight: normal;
  }
}

td.colspan-fix {
  display: none !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: $brand-color2 !important;
}

table.costs-to-validate-table {
  tr {
    td:last-of-type,
    th:last-of-type {
      min-width: 76px;
    }
  }
}

iframe#angular-oauth-oidc-silent-refresh-iframe {
  display: none;
}

.m-card-user.m-card-user--skin-dark .m-card-user__details .m-card-user__name,
.m-card-user.m-card-user--skin-dark .m-card-user__details .m-card-user__email {
  color: #fff !important;
}

.input-group-addon {
  background-color: $danger;
  border-right: 1px solid #ccc;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom: 0;
}

.mat-dialog-container > :first-child {
  height: 100%;
  display: block;
}

button:disabled {
  cursor: not-allowed;
}

mat-checkbox.mat-checkbox-disabled {
  .mat-checkbox-inner-container {
    cursor: not-allowed !important;
  }
}
input[type="checkbox"]:disabled,
input[type="radio"]:disabled,
.cursor-not-allowed {
  cursor: not-allowed !important;
}

input[type="date"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="datetime-local"]::-webkit-outer-spin-button,
input[type="datetime-local"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

div.loading-spinner {
  display: inline-block;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  margin-right: 5px;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 2px solid rgba(66, 66, 66, 0.2);
  border-right: 2px solid rgba(66, 66, 66, 0.2);
  border-bottom: 2px solid rgba(66, 66, 66, 0.2);
  border-left: 2px solid #424242;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: 0.8s linear infinite load8;
  animation: 0.8s linear infinite load8;
}

.m-portlet.m-portlet--rounded-force {
  overflow: revert !important;
}

ejs-documenteditorcontainer {
  display: block;
  height: 600px;
}

@font-face {
  font-family: "miso";
  src: url("assets/fonts/miso.otf") format("opentype");
}

@font-face {
  font-family: "miso-light";
  src: url("assets/fonts/miso-light.otf") format("opentype");
}

@font-face {
  font-family: "miso-bold";
  src: url("assets/fonts/miso-bold.otf") format("opentype");
}

@font-face {
  font-family: "Bebas Neue";
  src: url("assets/fonts/BebasNeue-Regular.ttf") format("truetype");
}

.mat-table-header-number {
  text-align: right !important;
  justify-content: flex-end !important;

  .mat-sort-header-button {
    text-align: right !important;
  }
}

.ngb-tp-input-container .ngb-tp-input {
  padding: 0 !important;
}

.ng2-tag-input.minimal {
  top: -14px;
}

// tag chip input

input.ng2-tag-input__text-input,
tag {
  font-family: $default-font;
  border-radius: 4px !important;
}

tag.ng-star-inserted {
  background: $primary !important;
  color: white !important;
}

tag.ng-star-inserted {
  background: $primary !important;
  color: white !important;
}

tag:hover,
tag:focus {
  box-shadow: none !important;
}

delete-icon {
  transform: none !important;
  -webkit-transform: none !important;
}

.ng2-tag-input {
  top: -13px !important;
  border-bottom: none !important;
}

.ng2-tag-input__text-input {
  background: none !important;
}

table.operational-files-table > tbody > tr {
  background-color: #fff !important;
}

table.operational-files-table > tbody > tr:nth-child(4n + 1) {
  background-color: $contrast !important;
}

table.operational-files-table > tbody > tr.highlight {
  background-color: #928cd6 !important;
}

.cdk-global-scrollblock {
  position: static !important;
  width: initial !important;
  overflow-y: inherit !important;
}

.ng-invalid-input-group > input,
.ng-invalid-input-group > .input-group-append > * {
  border-color: $danger !important;
}

.ng-invalid-input-group > input {
  border-right: inherit;
}

.ng-invalid-input-group > .input-group-append > * {
  border-left: inherit;
}

tag-input-form {
  width: 25% !important;
}

tag-input-form > form > input {
  width: 100% !important;
}

.date-input {
  width: 100%;
}

date-input.ng-untouched.ng-dirty.ng-invalid > div,
date-time-input.ng-pristine.ng-invalid.ng-touched > div > div > input,
date-time-input.ng-pristine.ng-invalid.ng-touched input.ngb-tp-input.form-control,
date-time-input.ng-pristine.ng-invalid.ng-touched button:not(.ngb-dp-arrow-btn):not(.btn.btn-primary.m-btn.w-100) {
  border: solid 1px $danger !important;
}

date-time-input.is-invalid > div > div > input,
date-time-input.is-invalid input.ngb-tp-input.form-control,
date-time-input.is-invalid button:not(.ngb-dp-arrow-btn):not(.btn.btn-primary.m-btn.w-100) {
  border: solid 1px $danger !important;
}

.exchange-fixed-width {
  min-width: fit-content !important;
  flex-grow: 1;
}

div.dropdown-menu button.dropdown-item {
  margin-left: 0 !important;
  cursor: pointer;
}

.btn-primary:not(:disabled):not(.disabled):focus,
.btn-primary:not(:disabled):not(.disabled).focus,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #2e40d4;
  border-color: #293ccc;
}

date-time-input button.btn:hover,
date-time-input button.btn:focus,
date-input button.btn:hover,
date-input button.btn:focus {
  border-color: #716aca;
  color: #575962;
  box-shadow: none;
}

date-time-input button.btn:disabled,
date-input button.btn:disabled {
  border: none !important;
  color: inherit;
}

ngb-alert > i[class^="flaticon-"] {
  margin-right: 0.5rem !important;
}

td.mat-cell.cdk-column-details.mat-column-details.ng-star-inserted,
td.mat-cell.cdk-column-actions.mat-column-actions.ng-star-inserted {
  width: 100px;
}

td.mat-cell.cdk-column-isSelected.mat-column-isSelected.ng-star-inserted {
  width: 45px;
}

.ng-value > company-address-option > .company-address-option {
  padding-top: 59px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.break-spaces {
  white-space: break-spaces;
}

.max-width-400 {
  max-width: 400px;
}

.content {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

td,
span,
h1,
h2,
h3,
h4,
h5,
h6[clipboard] {
  &:hover {
    i.copy-icon {
      cursor: pointer;
    }
  }

  i.copy-icon {
    position: relative;
    top: 1px;
    font-size: 1.5rem;
  }
}
h3.incoming-invoice-header > span:hover {
  font-weight: bold;
  cursor: pointer;
}

.pivot-container *:not(.e-icons) {
  font: inherit !important;
}

.pivot-container .e-text-content,
.pivot-container .e-title-content,
.pivot-container .e-rowcell * {
  font-size: 14px !important;
}

.pivot-container .e-headertext {
  font-size: 14px !important;
  font-weight: bold !important;
}

.e-field-list-container .e-list-item .e-text-content .e-list-text {
  position: relative;
  top: 4px;
}

.pivot-container .e-columnsheader .e-headercelldiv,
.pivot-container .e-columnheader .e-headercelldiv {
  text-align: right !important;

  .e-headertext {
    color: rgba(0, 0, 0, 0.54) !important;
  }
}

table.no-wrap-cells tbody td,
table tbody td.no-wrap {
  word-wrap: break-word;
  white-space: nowrap;
}

.horizontal-scroll {
  overflow-x: auto;
}

.modal-header.error-title {
  background-color: #f4516c !important;

  .modal-title {
    color: white !important;
  }
}

.popover-header {
  font-size: 1.2rem;
}

.popover-body {
  font-size: 1rem;
}

span.ng-option-label.ng-star-inserted {
  white-space: pre-wrap;
}

div.dropdown-menu button.dropdown-item.disabled {
  cursor: default;
}

.card-header.bg-success > button,
.card-header.bg-danger > button {
  color: white;
}

.vertical-stretch {
  height: 100%;
}

.document-select {
  .total-padding {
    max-height: 16vh !important;
  }
}

.truncate-item {
  max-width: 20vw !important;
}

.highlight,
.mat-table .mat-row:hover {
  .popover {
    h3,
    p,
    span,
    i {
      color: $black !important;
    }
    a {
      color: $primary !important;
    }
    .text-success {
      color: $success !important;
    }
    .text-danger {
      color: $danger !important;
    }
    .text-warning {
      color: $warning !important;
    }
  }
}

span.container-goods-label-ellipsis,
span.container-number-label-ellipsis {
  vertical-align: top;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}

span.container-goods-label-ellipsis {
  max-width: 100px;
}

span.container-number-label-ellipsis {
  max-width: 200px;
}

table.expandable-table > tbody > tr {
  background-color: #fff !important;
}

table.expandable-table > tbody > tr:nth-child(4n + 1) {
  background-color: $contrast !important;
}

table.expandable-table > tbody > tr.highlight {
  background-color: #928cd6 !important;
}

div.top-row {
  padding: 8px;
}

div.selectable {
  padding: 8px;
}

div.odd-active {
  background-color: $contrast;
}

div.selectable:hover {
  color: white;
  background-color: lighten($primary, 10%);
  cursor: pointer;
}

div.selectable:hover .mat-checkbox-frame {
  border-color: #ffffff !important;
}

div.selected {
  color: white;
  background-color: $primary !important;
}

.list-item-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 0 16px;
  position: relative;
  height: inherit;
}

a.hyperlink {
  color: blue !important;
}

a.hyperlink:hover {
  text-decoration: underline !important;
  cursor: pointer !important;
}

a.hyperlink:active {
  color: purple !important;
}

.alert-warning {
  &:hover {
    color: #ffffff !important;
  }
  color: #212529 !important;
}

.alert-text {
  &:hover {
    color: #ffffff !important;
  }
  color: #212529 !important;
}

.dynamic-viewer-height {
  height: 40vh;
}

double-sided-slider {
  height: 100%;
}

.dropdown-item.disabled {
  cursor: not-allowed !important;
}

.sticky-message-position {
  position: sticky;
  left: 0;
}

.m-topbar__notifications i.flaticon-alarm {
  color: $primary;
}

.break-word {
  word-wrap: break-word;
}
th[mat-header-cell] {
  position: inherit !important;

  &[class*="isBooked"],
  &[class*="isInvoiced"],
  &[class*="isSelected"],
  &[class*="details"],
  &[class*="actions"] {
    position: sticky !important;
  }
}

.arrow-down {
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #2f2f2f;
}

.btn.dropdown-toggle:after,
.btn.dropdown-toggle::after {
  display: inline-block !important;
  border-bottom-color: rgb(255, 255, 255) !important;
  border-bottom-style: none !important;
  border-bottom-width: 0px !important;
  border-left-color: rgba(0, 0, 0, 0) !important;
  border-left-style: solid !important;
  border-left-width: 3.10938px !important;
  border-right-color: rgba(0, 0, 0, 0) !important;
  border-right-style: solid !important;
  border-right-width: 3.10938px !important;
  border-top-color: rgb(255, 255, 255) !important;
  border-top-style: solid !important;
  border-top-width: 3.10938px !important;
  content: "" !important;
}

.btn.btn-secondary.dropdown-toggle:after,
.btn.btn-secondary.dropdown-toggle::after {
  border-bottom-color: #212529 !important;
  border-top-color: #212529 !important;
}

i.la-icon {
  font-size: 2rem;

  &:hover {
    cursor: pointer;
  }
}

.max-width-80 {
  max-width: 80vw;
}

.max-width-70 {
  max-width: 70vw;
}

.max-width-60 {
  max-width: 60vw;
}

.max-width-50 {
  max-width: 50vw;
}

.max-width-45 {
  max-width: 45vw;
}

.max-width-40 {
  max-width: 40vw;
}

.max-width-30 {
  max-width: 30vw;
}

.cdk-column-isSelected {
  width: 15px !important;
  padding: 0 5px 0px 5px !important;
}

.max-width-20 {
  max-width: 20vw;
}

.max-width-15 {
  max-width: 15vw;
}

.max-width-10 {
  max-width: 10vw;
}

.max-width-5 {
  max-width: 5vw;
}

.max-width-50ch {
  max-width: 50ch;
}
.max-height-80 {
  max-height: 80vh !important;
}

.checkbox-large {
  transform: scale(1.2);
}

.is-invalid-timepicker input {
  border: solid 1px $danger !important;
}

.error-text {
  color: $danger;
}

.cursor-default {
  cursor: default !important;
}

mat-toolbar.sticky {
  position: sticky;
  top: 136px !important;
  background: #fff !important;
  width: fit-content;
  margin-left: auto;
  z-index: 1500 !important;
  padding: 0 2px 2px 2px !important;
  height: fit-content;
  border-radius: 2.5px;

  .list-action-buttons > button:first-child {
    margin-left: 0px !important;
  }
}

.separator-right {
  border-right: 1px solid $contrast-dark;
}

.separator-bottom {
  border-bottom: 1px solid $contrast-dark;
}

.separator-top {
  border-top: 1px solid $contrast-dark;
}

.modal {
  z-index: 2020 !important;
}

ngb-modal-backdrop {
  z-index: 2019 !important;
}

.cdk-overlay-container {
  z-index: 2051 !important;
}

.m-header-menu .m-menu__nav > .m-menu__item .m-menu__submenu,
.m-header--fixed .m-header {
  z-index: 2010 !important;
}

.e-dlg-container.e-dlg-center-center {
  z-index: 2050 !important;
}

.ng-select-55-vw-max-width {
  max-width: 55vw !important;
}

.ng-select-28-vw-max-width {
  max-width: 55vw !important;
}

.ng-select-col-9-max-width {
  max-width: 32vw !important;
}

mat-toolbar div.list-action-buttons {
  margin-left: -2px;
}

.ng-trigger-slide.parent > .child {
  padding: 1px;
}

.ng-select-multiple {
  &,
  .ng-select-container {
    height: fit-content !important;
  }

  .ng-select-container {
    padding: 0.5em !important;
  }

  .ng-value-container {
    flex-direction: row;
    padding-right: 1em !important;
    width: 100%;
    overflow: hidden;
    gap: 0.2rem;
    min-height: fit-content;

    .ng-input {
      flex: 1 1 8ch !important;
    }

    .ng-value.remaining-values {
      flex: 0 0 8ch !important;
    }

    .ng-value:not(.remaining-values) {
      flex: 0 1 auto !important;
      display: grid !important;
      grid-auto-flow: column;
      grid-template-columns: 1fr fit-content;

      span {
        vertical-align: middle;
        font-size: 0.9rem;

        &:nth-child(1) {
          min-width: 3ch;
          width: auto;
          max-width: 10ch;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-left: 0.3em !important;
          padding-right: 0.6em !important;
        }

        &:nth-child(2) {
          padding-right: 0.3em !important;
          padding-left: 0.6em !important;
          transform: translateY(-0.05em);
        }
      }
    }
  }

  &.one-line .ng-value-container {
    flex-wrap: nowrap !important;
  }
}

.general-details-form .form-group {
  display: flex;

  & > *:nth-child(1) {
    width: 25%;
  }
  & > *:nth-child(2) {
    width: 75%;
  }
}

span.badge.badge-secondary.chip {
  display: inline-flex !important;
  flex-flow: row wrap;

  & > span.ellipsis {
    flex: 1;
    white-space: pre;
    max-width: 40vw;
    @extend .truncate;
  }
}

.input-group.ng-invalid-input-group.exchange-rate {
  box-shadow: 0 0 0 0.7px $danger !important;
  padding-left: 1px;
  border-radius: 2px;

  * {
    border-color: transparent !important;
  }
}

.ng-select.ng-select-disabled .ng-arrow-wrapper {
  display: none;
}

.form-field-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;

  & > *:first-child {
    padding-top: 0.4rem;
    flex: 0 0 70%;
  }

  & > *:last-child {
    padding-bottom: 0.3rem;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 100px inset !important;
  -webkit-box-shadow: 0 0 0 100px #fff inset !important;
}

.sticky-start-th-td {
  left: -1px !important;
}
.sticky-end-th-td {
  right: -1px !important;
}

.sticky-end-th-td-actions {
  right: 0px;
  min-width: 80px;
  max-width: 80px;
}

.sticky-end-th-td-status {
  right: 80px !important;
}

.wrap-long-text {
  word-wrap: break-word;
  width: 100%;
}

.files-list {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px auto;
  background: #ffffff;
  border: 1px dashed;
  border-radius: 12px;
  padding: 5px;
  color: #1c8adb;
}

.files-list .delete-file {
  background: transparent;
  border: none;
  cursor: pointer;
  & i {
    color: red;
    font-size: 22px;
  }
}

.upload-file-container {
  background-image: url("./assets/img/dragDrop/cloud.png");
  background-repeat: no-repeat;
  background-size: 100px;
  background-position: center;
  height: 200px;
  width: 100%;
  border: 2px dashed #1c8adb;
  border-radius: 10px;
  margin-bottom: 10px;
}

.upload-file-container:hover {
  cursor: pointer;
  background-color: #9ecbec !important;
  opacity: 0.8;
}

.badge-purple {
  color: #fff;
  background-color: #9a25f2;
}
